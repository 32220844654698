.subpage {
  padding: 2em 0;
  .head__title {
    @include flexer(space-between, center);
    h3 {
      margin: 0;
    }
    .illus {
      background-image: url(../../img/headillus.png);
      background-size: 120px 50px;
      height: 50px;
      width: 50%;
      background-repeat: repeat-x;
    }
  }
  .tabs__section {
    @include sm {
      border-bottom: 1px solid #77777736;
    }
    ul {
      justify-content: space-between;
      @include sm {
        justify-content: flex-start;
      }
      li {
        width: 100%;
        margin-bottom: 1em;
        @include md {
          width: calc((100% / 3) - 5px);
        }
        @include sm {
          margin-bottom: 0.5em;
          width: calc((100% / 2) - 5px);
        }
        button {
          width: 100%;
          border: 0;
          transition: 0.3s;
          border-radius: 0;
          padding: 1em;
          text-align: left;
          border-bottom: 1px solid #77777736;
          @include md {
            border-bottom: 0;
          }
          @include md {
            padding: 0.5em;
          }
          @include sm {
            font-size: 0.8em;
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
        button.active {
          font-weight: 600;
          border-bottom: 1px solid #77777736;
          @include md {
            border-bottom: 0;
          }
          svg {
            fill: #fff;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .tab-content {
    @include md {
      padding: 1em 0;
    }
  }
}

.service__subpage {
  padding: 2em 0;
  .head__title {
    @include flexer(space-between, center);
    margin-top: 0;
    h3 {
      margin: 0;
    }
    .illus {
      background-image: url(../../img/headillus.png);
      background-size: 120px 50px;
      height: 50px;
      width: 50%;
      background-repeat: repeat-x;
    }
  }
  .page__list {
    position: sticky;
    top: 120px;
    ul {
      padding: 0;
      list-style: none;
      li {
        padding: 1em;
        border-radius: 4px;
        background-color: $secondary-color3;
        margin-bottom: 0.5em;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          text-decoration: none;
          color: #000;
        }
      }
    }
  }
  .accordion-item {
    .accordion-button {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    .accordion-button.collapsed {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    .accordion-button:not(.collapsed) {
      background-color: $primary-color;
      color: #fff;
    }
  }
}
