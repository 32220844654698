.main__btn {
  background-color: $primary-color;
  border-radius: 24px;
  color: #fff;
  padding: 0.4em 1em;
  transition: 0.3s;
  text-decoration: none;
  display: inline-block;
  @include xl {
    padding: 0.2em 1em;
  }
}

.second__btn {
  background-color: #fff;
  border-radius: 24px;
  border: 1px solid #00000063;
  padding: 0.4em 1em;
  color: #00000063;
  transition: 0.3s;
  text-decoration: none;
  display: inline-block;
  text-align: center;
}

.head__title {
  font-size: $medium-font;
  margin: 2em 0 3em 0;
  @include md {
    margin: 1em 0 2em 0;
  }
  h3 {
    display: inline-block;
    position: relative;
    margin: 0;
    &:after {
      position: absolute;
      content: "";
      top: 50%;
      left: 105%;
      transform: translateY(-50%);
      background-color: $primary-color;
      height: 1px;
      width: 50%;
      @include lg {
        width: 30%;
      }
      @include md {
        display: none;
      }
    }
    @include sm {
      font-size: $large-font;
    }
  }
}

.left-fluid {
  width: 95%;
  margin-left: 5%;
  @include md {
    width: 90%;
    margin: 0 auto;
  }
}
.right-fluid {
  width: 95%;
  margin-right: 5%;
  @include md {
    width: 90%;
    margin: 0 auto;
  }
}

.topper {
  background-image: url(../img/background_banner.png);
  background-size: 100% 64%;
  background-repeat: no-repeat;
  @include lg {
    figcaption {
      flex-wrap: wrap;
      .infos {
        width: 100%;
        h3 {
          font-size: $medium-font !important;
        }
      }
      .action {
        width: 100%;
        a {
          padding: 1px 4px;
          font-size: $small-font;
        }
      }
    }
  }
  @include md {
    display: none;
  }
}

.full__height {
  background-size: 100% 100% !important;
  .head__title {
    margin: 1em 0;
  }
}

.formation {
  @include flexer(space-between, center);
  .formation_item {
    width: 30%;
    background-color: $primary-color;
    position: relative;
    .head {
      padding: 1em;
      h3 {
        font-size: 1.2em;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        margin: 0;
      }
      span {
        font-size: 0.8em;
        color: #fff;
      }
    }
    .slids {
      position: absolute;
      top: 100%;
      width: 100%;
      visibility: hidden;
      display: none;
      opacity: 0;
      overflow: hidden;
      transition: 0.3s;
      z-index: 1;
      background-color: $primary-color;
      .item {
        a {
          text-decoration: none;
          color: #fff;
          display: block;
          padding: 0.51em 2em 0.5em 2em;
          position: relative;
          transition: 0.3s;
          &:before {
            content: ">";
            left: 1em;
            top: 50%;
            position: absolute;
            transform: translateY(-50%);
          }
          &:hover {
            background-color: $secondary-color2;
          }
        }
      }
    }
    &:hover {
      .slids {
        opacity: 1;
        visibility: visible;
        display: block;
      }
    }
  }
}

.fb {
  background-color: $secondary-color3;
  text-align: center;
  padding: 1em;
  @include md {
    display: none;
  }
}

.stick_btn {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  bottom: 10%;
  left: 5%;
  z-index: 4;
  box-shadow: 0 0 1;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.blog-mar {
  background-color: #fff;
  width: 70% !important;
  margin: auto;
  margin-top: -120px !important;
  @include lg {
    margin-top: -100px !important;
  }
  @include md {
    width: 80% !important;
  }
  @include md {
    width: 90% !important;
    margin-top: 0 !important;
  }
}
