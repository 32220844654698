$xsm: 420px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

//For Extra Small
@mixin xsm {
  @media screen and (max-width: ($xsm)) {
    @content;
  }
}

//For Small
@mixin sm {
  @media screen and (max-width: ($sm)) {
    @content;
  }
}

//For Medium
@mixin md {
  @media screen and (max-width: ($md)) {
    @content;
  }
}

//For large
@mixin lg {
  @media screen and (max-width: ($lg)) {
    @content;
  }
}

//For Extra Large
@mixin xl {
  @media screen and (max-width: ($xl)) {
    @content;
  }
}

//For Extra Extra Large
@mixin xxl {
  @media screen and (max-width: ($xxl)) {
    @content;
  }
}

//Flexboxes
@mixin flexer($justify, $align) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}
@mixin full-flexer {
  display: flex;
  flex-wrap: wrap;
}

//Flex UL
@mixin flex-menu {
  padding: 0;
  margin: 0;
  display: flex;
  list-style: none;
}

@mixin loop($count) {
  @for $i from 1 through $count {
    &:nth-child(#{$i}) {
      transition: $i/5 + s;
    }
  }
}
