.blog__item {
  .banner {
    img {
      height: 280px;
      object-fit: cover;
    }
  }
  .blog__meta {
    padding: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include sm {
      flex-wrap: wrap;
    }
    .lf {
      width: 70%;
      @include sm {
        width: 100%;
        margin-bottom: 1em;
      }
      h2 {
        font-size: $medium-font;
        a {
          text-decoration: none;
          color: #000;
          font-weight: 600;
          display: block;
        }
      }
      .blog__date {
        font-size: $small-font;
      }
    }
    .rf {
      width: 28%;
      text-align: right;
      @include sm {
        width: 100%;
        text-align: left;
      }
    }
  }
}

.blogs {
  padding: 1em 0;
}

.pagination {
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  nav {
    ul {
      .page-item {
        margin: 0 4px;
        a {
          color: #a1a1a1;
          border: 1px solid #a1a1a1;
          svg {
            width: 8px;
          }
        }
        &:last-child,
        &:first-child {
          border: 0;
          a {
            border: 0;
          }
        }
      }
      .page-item.active {
        a {
          background-color: #a1a1a1;
          border: 1px solid #a1a1a1;
        }
      }
    }
  }
}

.detail_blog {
  .top__blog {
    padding: 2em;
    min-height: 400px;
    background-color: $secondary-color2;
    @include flexer(space-between, center);
    .left__nav,
    .right__nav {
      width: 18%;
      @include md {
        display: none;
      }
      a {
        text-decoration: none;
        svg {
          width: 24px;
          height: 24px;
        }
        h4 {
          font-size: $medium-font;
          color: #000;
        }
      }
    }
    .right__nav {
      text-align: right;
      @include md {
        width: 80%;
      }
    }
    .blog__title {
      h3 {
        width: 60%;
        margin: auto;
        font-size: $larger-font;
        font-weight: 600;
        text-align: center;
        @include sm {
          font-size: $medium-font;
        }
      }
    }
  }
  .row {
    @include sm {
      flex-direction: column-reverse;
    }
    .sidebar {
      position: sticky;
      top: 120px;
      .head__title {
        margin: 1em 0;
        h3 {
          margin: 0;
          color: $primary-color;
        }
      }
    }
    .single__blog {
      padding: 1em 2em;
      position: relative;
      .shad__left {
        position: absolute;
        content: "";
        left: -14px;
        width: 14px;
        height: 30px;
        top: 120px;
        img {
          width: 14px;
          height: 30px;
        }
        @include lg {
          top: 100px;
        }
        @include md {
          display: none;
        }
      }
      .shad__right {
        position: absolute;
        content: "";
        right: -14px;
        width: 14px;
        height: 30px;
        top: 120px;
        @include lg {
          top: 100px;
        }
        @include md {
          display: none;
        }
        img {
          width: 14px;
          height: 30px;
        }
      }
      .blog__meta {
        margin: 1em 0;
        @include flexer(space-between, center);
        .author {
          img {
            width: 20px;
            height: 20px;
            border-radius: 50%;
          }
          span {
            font-weight: 600;
          }
        }
        .blog__date {
          margin-left: 1em;
          color: $blog-color;
        }
      }
    }
  }
  .bottom__nav.mob {
    display: none;
    @include md {
      @include flexer(space-between, center);
    }
    @include sm {
      flex-wrap: wrap;
    }
  }
  .bottom__nav {
    margin: 2em 0;
    .left__nav,
    .right__nav {
      width: 48%;
      @include sm {
        width: 100%;
      }
      a {
        text-decoration: none;
        svg {
          width: 24px;
          height: 24px;
        }
        h4 {
          font-size: $medium-font;
          color: #000;
        }
      }
    }
    .right__nav {
      text-align: right;
    }
  }
}
