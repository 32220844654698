$primary-color: #1fabcc;
$secondary-color: #0ba5ce6e;
$secondary-color2: #43cbefa1;
$secondary-color3: #43cbef29;
$secondary-color4: #087d99cc;
$primary-color2: #ffcb08;
$pink-color: #ec008c;

$black: #000;
$black-light: #0000000d;

$foot-black: #222;
$foot-grey: #3c3c3c;

$blog-color: #aaa3a3;

$border-color: #77777736;
