@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
}
@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: Poppins;
  src: url("../fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

$small-font: 0.8em;
$medium-font: 1em;
$large-font: 1.4em;
$larger-font: 2em;
$largest-font: 3em;
