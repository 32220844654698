@import "./variables/colors";
@import "./variables/fonts";
@import "./mixins";
@import "./variables/others";
@import "./partials/header";
@import "./partials/footer";
@import "./pages/home";
@import "./pages/contacts";
@import "./pages/about";
@import "./pages/blogs";
@import "./pages/subpage";
@import "./pages/listings";

html {
  scroll-behavior: smooth;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: Poppins;
  font-weight: 400;
}

* {
  box-sizing: border-box;
}

img {
  width: 100%;
  height: auto;
}

.wrapper {
  width: 90%;
  margin: 0 auto;
}

figure {
  margin: 0;
}
