section.aboutus {
  padding: 2em 0;
  margin-top: 1em;
  .head__title {
    @include flexer(space-between, center);
    h3 {
      margin: 0;
    }
  }
  .tabs__section {
    position: sticky;
    top: 120px;
    ul {
      justify-content: space-between;
      @include sm {
        border: 0;
      }
      li {
        width: 100%;
        margin-bottom: 1em;
        @include md {
          width: calc(100% / 4);
        }
        @include sm {
          width: calc(100% / 2);
        }
        button {
          width: 100%;
          border: 0;
          transition: 0.3s;
          border-radius: 0;
          padding: 1em;
          text-align: left;
          border-bottom: 1px solid #77777736;
          @include md {
            padding: 0.5em;
          }
          @include sm {
            font-size: 0.8em;
          }
          svg,
          img {
            width: 20px;
            height: 20px;
          }
          img {
            object-fit: cover;
          }
        }
        button.active {
          color: #000;
          font-weight: 600;
          border-bottom: 1px solid #77777736;
          svg {
            fill: #000;
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .tab-pane {
    .contents {
      .abt__row {
        justify-content: space-between;
        display: flex;
        @include sm {
          flex-direction: column-reverse;
        }
        .desc {
          width: 62%;
          @include sm {
            width: 100%;
          }
        }
        .image {
          width: 33%;
          @include sm {
            width: 100%;
            margin: 1em 0;
          }
        }
      }
    }
  }
}

section.figures {
  background-color: $secondary-color;
  padding: 1em 0;
  .facts {
    .fact__row {
      @include full-flexer;
      justify-content: space-between;
      .fact_item {
        width: calc((100% / 4) - 10px);
        @include sm {
          width: calc((100% / 2) - 10px);
        }
        text-align: center;
        padding: 0.5em;
        svg {
          display: block;
          width: 50px;
          height: 50px;
          margin: 0 auto;
          fill: #fff;
        }
        h3 {
          font-size: $small-font;
          margin: 10px 0;
          color: $primary-color;
        }
        span {
          font-size: $larger-font;
          font-weight: 600;
        }
      }
    }
  }
}
