.locations__row {
  @include flexer(flex-start, flex-start);
  flex-wrap: wrap;
  gap: 1em;
  .loc__item {
    background-color: rgba(67, 203, 239, 0.160784);
    padding: 1em;
    width: calc(50% - 1em);
    @include flexer(space-between, center);
    @include lg {
      width: 100%;
    }
    .loc__lf {
      width: 200px;
      height: 100%;
      margin-right: 1em;
      img {
        width: 100%;
        height: 140px;
        object-fit: cover;
      }
    }
    .loc__rt {
      flex-grow: 2;
      .loc__infos {
        h3 {
          font-size: 1em;
        }
        p {
          margin: 0.5em 0;
        }
        span.price {
          font-weight: 600;
          display: block;
        }
        a {
          margin-top: 1em;
          background-color: $primary-color;
          color: #fff;
        }
      }
    }
  }
}
