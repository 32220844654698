section.banner {
  padding: 3em 0;
  .wrapper {
    @include flexer(flex-start, center);
    height: 100%;
    .row {
      @include md {
        flex-direction: row-reverse;
      }
      .banner__info {
        height: 100%;
        .packer {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          height: 100%;
          span {
            display: block;
            font-size: $large-font;
          }
          p {
            font-size: $largest-font;
            font-weight: 900;
            margin: 0.4em 0;
            line-height: 1.2em;
            @include xl {
              font-size: $larger-font;
            }
          }
          .main__btn {
            margin-top: 2em;
            font-size: $large-font;
          }
        }
      }
      .illustration {
        @include md {
          margin-bottom: 1em;
        }
      }
    }
  }
}

section.cards {
  padding-bottom: 3em;
  .article__item {
    position: relative;
    figure {
      img {
        height: 260px;
        object-fit: cover;
      }
    }
    figcaption {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 1em;
      margin: 0 auto;
      @include flexer(space-between, center);
      background: linear-gradient(0deg, #000, transparent);
      .infos {
        flex-grow: 2;
        h3 {
          font-size: $large-font;
          font-weight: 600;
          color: #fff;
          margin: 0;
        }
        p {
          font-size: $medium-font;
        }
      }
      .action {
        a {
          text-decoration: none;
        }
      }
    }
    @include lg {
      margin-bottom: 1em;
    }
  }
}

section.informations {
  padding: 3em 0;
  .experience {
    @include flexer(space-between, flex-start);
    flex-direction: column;
    height: 100%;
    h2 {
      font-size: $larger-font;
      font-weight: 600;
      margin: 0;
      @include lg {
        font-size: $large-font;
      }
    }
    p {
      margin: 1em 0;
      font-size: $small-font;
      font-weight: 500;
    }
  }
  .info__cards {
    @include full-flexer;
    justify-content: space-between;
    .info__card__item {
      width: 48%;
      padding: 1.5em;
      border-radius: 4px;
      background-color: $secondary-color3;
      @include flexer(space-between, flex-start);
      .descriptions {
        flex-grow: 2;
        margin-left: 1em;
        h3 {
          font-size: $large-font;
          font-weight: 600;
          @include lg {
            font-size: $medium-font;
          }
        }
        p {
          font-size: $small-font;
          margin: 0;
          font-weight: 500;
        }
      }
      &:nth-last-child(1) {
        margin-top: 1em;
        @include sm {
          margin-top: 0;
        }
      }
      &:nth-last-child(2) {
        margin-top: 1em;
        @include sm {
          margin-top: 0;
        }
      }
      .illustration {
        @include flexer(center, center);
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 50%;
        background-color: $primary-color;
        svg {
          width: 20px;
          height: 20px;
          fill: #fff;
        }
      }
      @include sm {
        width: 100%;
        margin-bottom: 0.8em;
      }
    }
    @include md {
      margin-top: 1em;
    }
  }
}

section.business {
  padding: 3em 0;
  .business__slider {
    position: relative;
    cursor: pointer;
    .owl-nav:not(.disabled) {
      position: absolute;
      right: 0;
      bottom: calc(100% + 3em);
      display: flex;
      button {
        border: 1px solid $primary-color;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        @include flexer(center, center);
        &:focus {
          outline: none;
        }
        @include sm {
          height: 40px;
          width: 40px;
        }
      }
      .owl-prev {
        margin-right: 1em;
        span {
          font-size: $larger-font;
          color: $primary-color;
        }
        @include sm {
          margin-right: 0.5em;
        }
      }
      .owl-next {
        background-color: $primary-color;
        span {
          font-size: $larger-font;
          color: #fff;
        }
      }
      @include sm {
        position: unset;
        justify-content: center;
      }
    }
    .business__item {
      position: relative;
      text-decoration: none;
      color: #000;
      &:hover {
        img {
          filter: blur(2px);
        }
      }
      img {
        height: 260px;
        object-fit: cover;
        transition: 0.3s;
      }
      figcaption {
        margin: 1em 0;
        font-weight: 600;
        h4 {
          display: flex;
          align-items: center;
          svg {
            height: 0.6em;
            width: 0.6em;
            margin-right: 0.4em;
          }
        }
      }
      .links__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 85%;
        padding: 1em;
        a {
          display: block;
          width: 0;
          height: calc((100% / 7) - 0.5em);
          text-decoration: none;
          padding: 0 0.6em;
          margin-bottom: 0.5em;
          background-color: #1fabccbf;
          color: #fff;
          opacity: 0;
          &:last-child {
            margin-bottom: 0;
          }
          @include loop(7);
          svg {
            height: 0.8em;
            width: 0.8em;
            fill: #fff;
            margin-right: 0;
            transition: 0.3s;
            visibility: hidden;
            opacity: 0;
          }
          &:hover {
            svg {
              margin-right: 0.5em;
              visibility: visible;
              opacity: 1;
            }
          }
        }
        &:hover {
          a {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }
}

section.values {
  padding: 3em 0;
  .core__img {
    height: 100%;
    img {
      clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 calc(100% - 80px));
      height: 100%;
      object-fit: cover;
      @include sm {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 calc(100% - 40px));
      }
    }
  }
  .core__desc {
    .core__item {
      display: flex;
      margin-bottom: 1em;
      &:last-child {
        margin-bottom: 0;
      }
      .icon__part {
        margin-right: 10px;
        .round {
          background-color: $primary-color;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          @include flexer(center, center);
          svg {
            width: 30px;
            height: auto;
            fill: #fff;
          }
        }
      }
      .desc__part {
        flex-grow: 2;
        h4 {
          font-size: $medium-font;
          font-weight: 600;
        }
        p {
          font-weight: 500;
        }
      }
    }
  }
}

section.companies {
  padding: 3em 0;
  .companies__item {
    img {
      width: 140px;
      display: block;
      margin: 0 auto;
      height: 140px;
      object-fit: contain;
    }
  }
}

section.query {
  margin-top: 80px;
  background-color: $secondary-color;
  .contact__form {
    @include flexer(flex-start, center);
    background-color: $primary-color;
    height: 100%;
    padding: 3em;
    position: relative;
    .packer {
      width: 100%;
      h3 {
        display: inline-block;
        color: #fff;
        font-size: $larger-font;
        padding-bottom: 0.5em;
        position: relative;
        &:after {
          position: absolute;
          content: "";
          width: 40%;
          height: 1px;
          background-color: #fff;
          bottom: 0;
          left: 0;
        }
        @include lg {
          font-size: $large-font;
        }
      }
      form {
        .form__flex.form__item {
          @include full-flexer;
          justify-content: space-between;
          input {
            width: 48%;
          }
        }
        .form__item {
          padding: 0.8em;
          margin-bottom: 1em;
          &:last-child {
            margin-bottom: 0;
          }
          input,
          textarea {
            border: 0;
            width: 100%;
            padding: 0.8em;
            background-color: transparent;
            border-bottom: 1px solid $secondary-color4;
            transition: 0.3s;
            color: #fff;
            &::placeholder {
              color: #fff;
            }
            &:focus {
              outline: none;
              border-bottom: 2px solid #fff;
            }
          }
          button {
            background-color: transparent;
            color: #fff;
            border-color: #fff;
          }
        }
      }
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      bottom: calc(100% - 1px);
      left: 0;
      height: 100px;
      background-color: $primary-color;
      clip-path: polygon(0 0, 100% 80px, 100% 100%, 0 100%);
      @include sm {
        height: 70px;
        clip-path: polygon(0 0, 100% 40px, 100% 100%, 0 100%);
      }
    }
  }
  .testimonials {
    padding: 2em;
    @include flexer(center, center);
    height: 100%;
    .customer__slider {
      .customer__quote {
        figure {
          margin: 0;
          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border-radius: 50%;
            display: block;
            margin: 0 auto;
          }
        }
        p {
          margin: 3em 0;
          font-size: $small-font;
          text-align: center;
        }
        .cust {
          display: block;
          text-align: center;
          font-size: $large-font;
        }
        .desig {
          display: block;
          text-align: center;
          font-size: $small-font;
          font-weight: 600;
        }
      }
      .owl-dots {
        margin-top: 40px;
        text-align: center;
        .owl-dot {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          border: 1px solid $secondary-color4;
          margin: 0 0.2em;
          transition: 0.3s;
        }
        .owl-dot.active {
          height: 8px;
          width: 30px;
          border-radius: 12px;
          background-color: $secondary-color4;
        }
      }
    }
  }
}
